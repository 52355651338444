import React from 'react';

import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ImageHeader from '../components/ImageHeader';

export default ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <ImageHeader
      img={data.cover.childImageSharp.fluid.src}
      title="Oh Nein!"
      subtitle="Leider existiert diese Seite nicht 😢"
      showButton
      cssProps={css`
        height: 100vh !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      `}
    />
  </Layout>
);

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "dead_end.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          src
        }
      }
    }
  }
`;
